import React from 'react';

//import './AboutUs.css';

function AboutUs() {
	return (
		<div className="main">
			AboutUs
		</div>
	);
}

export default AboutUs;