import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../svg/img-brand.svg';

import './Home.scss';

function Home() {
  return (
	<div className="main">
		<div className="section">
			<img src={logo} className="App-logo" alt="logo" />
			<p className="island">We are working on our pilot release so there is not much to see at this time but feel free to <Link className="text-link" to="/contact">contact&nbsp;us</Link> if you wish to place a memorial, obituary or funeral notice using our pilot program.</p>
			<p className="island"><a className="button-link" href="mailto:ask@inmemoriam.com?subject=In Memoriam Enquiry">Email&nbsp;Us</a></p>
		</div>
	</div>
  );
}
export default Home;