import React from 'react';
import { Switch, Route } from 'react-router-dom';

//import { AuthRoute } from '../../utils/routing';

import Memorial from './Memorial';

const MemorialRouter = () => (
	<Switch>
		<Route
			exact
			path="/:id"
			component={Memorial}
		/>
		<Route
			exact
			path="/:id/m/:momentID"
			component={Memorial}
		/>
		{/* <AuthRoute
			exact
			path="/:author/:id/m/:momentID/e/:edit"
			component={LifeTimeLine}
			signInRequired={true}
			isSignedIn={isSignedIn}
		/> */}
		<Route
			exact
			path="/:author/:id"
			component={Memorial}
		/>
		<Route
			exact
			path="/:author/:id/m/:momentID"
			component={Memorial}
		/>
	</Switch>
);

export default MemorialRouter;