import React, { useState, useEffect } from 'react';

import { Link, withRouter } from 'react-router-dom'
import Logo from '../Logo';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import NavBar from '../NavBar/NavBar';
import './Header.scss';
// see https://github.com/reactjs/react-transition-group/tree/v1-stable for future transitions

function Header(props) {

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const pn = props.location.pathname;

	useEffect(() => {
        props.history.listen(() => {
            setIsMenuOpen(false);
        });
	});

	function handleMenuToggle() {
		isMenuOpen ? setIsMenuOpen(false) : setIsMenuOpen(true);
	}

	const menuContent = (
		<div>
			<div>
				<ul>
					{/* <li className={pn === '/about' 		? 'active' : ''}><Link to='/about'>About</Link></li> */}
					<li className={pn === '/contact'	? 'active' : ''}><Link to='/contact'>Contact</Link></li>
				</ul>
			</div>
		</div>
	);

	return (
		<div id="header" className="header">
			<div id="header-box" className="header-box">
				<div className="header-item header-item--logo">
					<Link to='/'><Logo /></Link>
				</div>
				<div className="header-item header-item-menu">
					<HamburgerMenu
						isMenuOpen={isMenuOpen}
						menuContent={menuContent}
						handleMenuToggle={handleMenuToggle}
						/>
					<NavBar
						menuContent={menuContent}
						/>
				</div>

			</div>
		</div>
	);
}
export default withRouter(Header);