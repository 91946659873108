import React, { useState, useLayoutEffect, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

import './App.scss';

import Header		from '../components/Header/Header';
import Home			from '../containers/Home/Home';
import Memorial		from '../containers/Memorial';
import ContactUs	from '../containers/ContactUs/ContactUs';
import AboutUs		from '../containers/AboutUs/AboutUs';
import Footer 		from '../components/Footer/Footer';

function App() {

	useLayoutEffect(() => {
		window.addEventListener('scroll', handleHeader);
		return () => window.removeEventListener('scroll', handleHeader);
	});
	useLayoutEffect(() => {
		window.addEventListener('resize', handleHeader);
		return () => window.removeEventListener('resize', handleHeader);
	});
	useEffect(() => { // This is an onLoad to determine header if on mobile etc.
		handleHeader();
	}, []);

	const [headerIsSmall, setHeaderIsSmall] = useState(false);

	const handleHeader = () => {
		// TASK: change to use refs
		if ( window.pageYOffset < 5 ) {
			document.getElementById('header').classList.remove('header--shadow');
		}
		if ( window.pageYOffset >= 5 ) {
			document.getElementById('header').classList.add('header--shadow');
		}
		if ( window.pageYOffset >= 50 || window.innerWidth < 450 ) {
			setHeaderIsSmall(true);
		}
		if ( window.pageYOffset < 50 && window.innerWidth >= 450 ) {
			setHeaderIsSmall(false);
		}
	}

	const classes = [ 'App' ];
	if (headerIsSmall) {
		classes.push('header--small');
	}

	return (
		<div className={classes.join(' ')} id="app">
			<Header />
			<Switch>
				<Route exact path='/' component={Home}/>
				<Route path='/about' component={AboutUs}/>
				<Route path='/contact' component={ContactUs}/>
				<Route
					path="/" // See Memorial index.js for routes
					render={(routeProps) => <Memorial {...routeProps} />}
				/>
			</Switch>
			<Footer />
		</div>
	);
}

export default App;
