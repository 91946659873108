import React from 'react';

import './HamburgerMenu.scss';

function HamburgerMenu({ isMenuOpen, menuContent, handleMenuToggle }) {

	return (
		<div className="menu-wrap">
			<div className={`hamburger ${isMenuOpen ? "hamburger-open" : ""}`} id="hamburger" onClick={handleMenuToggle}>
				<div></div>
			</div>
			<div className={`menu ${isMenuOpen ? "menu-open" : ""}`}>
				{menuContent}
			</div>
		</div>
  );
}
export default HamburgerMenu;