import React from 'react';
import './Logo.scss';

function Logo() {
	return (
	<div className="brand-box">
		<svg
			className="brand-logo"
			version="1.1"
			id="in_memoriam_logo"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 113.3857422 100.2963867" >
			<path fill="#56B3E9" d="M19.1894531,68.0717773c-5.1962891,0.6191406-8.1708984,0.6191406-10.5336914,0.2402344 c8.2060547-2.5527344,12.7412109-4.9042969,16.1035156-6.4628906c7.8022461-3.6113281,17.3378906-9.1738281,24.6982422-15.9926758 c7.4077148-6.8286133,12.4926758-14.7080078,12.4467773-22.3188477 c-0.0097656-7.2402344-4.3720703-15.1821289-17.4599609-23.3051758C34.2651367-0.5444336,24.784668,0.574707,17.6113281,4.3730469 C-7.1040039,17.4589844-3.7226563,67.1889648,14.875,100.2963867h9.4433594 c-3.2939453-9.4082031-6.034668-18.625-6.065918-25.9765625C18.2548828,72.081543,18.5170898,69.9838867,19.1894531,68.0717773z  M23.2822266,39.512207c1.2851563,0,2.3295898,1.0400391,2.3295898,2.3266602c0,1.2861328-1.0444336,2.3266602-2.3295898,2.3266602 c-1.284668,0-2.3295898-1.0405273-2.3295898-2.3266602C20.9526367,40.5522461,21.9975586,39.512207,23.2822266,39.512207z  M110.359375,32.3530273C104.4101563,19.3867188,77.9501953,5.5073242,52.9692383,1.2875977 c9.7475586,7.1225586,14.0073242,14.1665039,13.9975586,22.25c-0.0429688,9.8935547-6.1972656,18.7250977-14.0712891,26.0341797 c-7.9189453,7.3173828-17.7607422,13.1953125-26.0776367,16.9042969c-1.2436523,0.5703125-1.9824219,1.3378906-2.5859375,2.5947266 c-0.5869141,1.2519531-0.9213867,3.0380859-0.9174805,5.2490234c-0.0327148,6.4765625,2.855957,16.1357422,6.3623047,25.9765625 h18.5751953c-0.4384766-3.8330078-1.5888672-14.0146484-1.5205078-14.8359375 c0.0839844-1.0117188,0.675293-1.9404297,2.277832-2.2773438c1.9970703-0.4199219,2.6811523,1.3447266,2.7001953,2.0253906 c0.0717773,2.5820313,0.9960938,11.3671875,1.5830078,15.0878906h20.5483398 c-0.0673828-4.2597656,0.1904297-8.0429688,0.6503906-10.8691406c0.1640625-1.0029297,0.9277344-1.7714844,2.53125-1.6035156 c1.6044922,0.1689453,2.4052734,1.3076172,2.2783203,2.53125c-0.2412109,2.328125-0.3857422,5.8847656-0.3789063,9.9414063 h21.3261719C114.0136719,76.2202148,116.1826172,45.0458984,110.359375,32.3530273z"/>
		</svg>
		<div className="brand-masthead">
			<svg
				version="1.1"
				className="brand-masthead-inmemoriam"
				id="in_memoriam_masthead"
				xmlns="http://www.w3.org/2000/svg"
				x="0px"
				y="0px"
				viewBox="0 0 340.1582031 43.2055664"
				>
				<g>
					<rect width="3.6591797" height="42.4731445"/>
					<path d="M34.4160156,42.4731445h3.2958984v-21.234375c0-6.9604492-3.6591797-10.2563477-9.5234375-10.2563477 c-4.3862305,0-8.7836914,2.9326172-10.9775391,5.4946289v-4.3930664h-3.3007813v30.3891602h3.3007813V19.4047852 c1.8237305-2.5644531,5.8530273-5.1264648,9.8813477-5.1264648c4.3984375,0,7.3237305,1.8354492,7.3237305,7.6870117V42.4731445"/>
					<polyline points="99.5966797,42.4731445 103.2553711,42.4731445 103.2553711,0 97.7607422,0 83.8510742,35.1489258 69.5717773,0  64.0771484,0 64.0771484,42.4731445 67.7358398,42.4731445 67.7358398,4.7607422 83.1118164,42.4731445 84.5776367,42.4731445  99.5966797,4.7607422 99.5966797,42.4731445 	"/>
					<path d="M126.3183594,14.2783203c7.6938477,0,10.9892578,6.2202148,10.9892578,11.3530273h-21.9667969 C115.7045898,20.4985352,119.3706055,14.2783203,126.3183594,14.2783203 M111.675293,27.0922852 c0,9.5214844,6.5913086,16.1132813,15.3818359,16.1132813c5.1264648,0,8.7851563-1.828125,11.7172852-5.125l-1.4667969-1.8359375 c-2.5625,2.5625-6.2216797,4.0292969-9.887207,4.0292969c-7.324707,0-11.7158203-5.4882813-12.0795898-12.0800781h25.2631836 v-0.7324219c0-8.7851563-4.7617188-16.4790039-14.2856445-16.4790039 C117.9033203,10.9824219,111.675293,18.3051758,111.675293,27.0922852z"/>
					<path d="M185.640625,42.4731445h3.2929688V20.4985352c0-6.2202148-2.5625-9.5161133-8.4199219-9.5161133 c-4.0273438,0-8.0546875,3.2958984-9.8867188,5.8579102c-0.7324219-2.925293-3.2954102-5.8579102-8.0571289-5.8579102 c-4.7607422,0-8.7900391,3.6650391-10.2504883,5.4946289v-4.3930664h-3.2958984v30.3891602h3.2958984V19.4047852 c1.8305664-2.5644531,5.4897461-5.1264648,9.152832-5.1264648c4.3925781,0,6.2285156,2.5620117,6.2285156,6.9604492v21.234375 h3.2895508V19.4047852c1.46875-2.9277344,5.1269531-5.1264648,8.4277344-5.1264648 c4.3847656,0,6.2226563,2.5620117,6.2226563,6.9604492V42.4731445"/>
					<path d="M212.0058594,40.2739258c-7.3261719,0-10.9902344-6.2226563-10.9902344-13.1816406 c0-6.59375,3.6640625-12.8139648,10.9902344-12.8139648c6.953125,0,10.9824219,6.2202148,10.9824219,12.8139648 C222.9882813,34.0512695,218.9589844,40.2739258,212.0058594,40.2739258 M212.0058594,43.2055664 c8.78125,0,14.6425781-7.3242188,14.6425781-16.1132813c0-8.7871094-5.8613281-16.1098633-14.6425781-16.1098633 c-8.7910156,0-14.6503906,7.3227539-14.6503906,16.1098633C197.3554688,35.8813477,203.2148438,43.2055664,212.0058594,43.2055664z "/>
					<path d="M235.0683594,42.4731445h3.2949219V20.1352539c1.1015625-2.5625,5.4960938-5.1235352,8.4199219-5.1235352 c0.7324219,0,1.1035156,0,1.4667969,0v-4.0292969c-4.0273438,0-7.3261719,2.9326172-9.8867188,6.2270508v-5.1254883h-3.2949219 V42.4731445"/>
					<path d="M254.8417969,42.4731445h3.2949219V12.0839844h-3.2949219V42.4731445z M256.3066406,6.5917969 c1.4667969,0,2.5605469-1.0976563,2.5605469-2.5634766c0-1.0966797-1.09375-2.5634766-2.5605469-2.5634766 c-1.0957031,0-2.1992188,1.4667969-2.1992188,2.5634766C254.1074219,5.4941406,255.2109375,6.5917969,256.3066406,6.5917969z"/>
					<path d="M287.4296875,36.2446289c-2.1953125,2.9316406-5.4882813,4.4003906-9.15625,4.4003906 c-4.7539063,0-8.0488281-3.2988281-8.0488281-7.6894531c0-4.3984375,3.2949219-7.3242188,8.0488281-7.3242188 c3.6679688,0,6.9609375,1.4609375,9.15625,4.0214844V36.2446289 M287.4296875,42.4731445h3.2949219V20.8696289 c0-6.5913086-4.7617188-9.887207-10.9824219-9.887207c-5.1289063,0-8.421875,1.8300781-11.7207031,5.4946289l1.8320313,2.1992188 c2.9316406-3.3012695,5.8574219-4.3979492,9.5234375-4.3979492c4.3925781,0,8.0527344,2.1987305,8.0527344,6.9604492v6.2226563 c-2.5664063-3.2949219-5.859375-4.3925781-10.25-4.3925781c-5.1269531,0-10.6210938,3.2949219-10.6210938,9.8867188 c0,6.5898438,5.4941406,10.25,10.6210938,10.25c4.390625,0,7.6835938-1.4648438,10.25-4.3984375V42.4731445z"/>
					<path d="M337.2246094,42.4731445h2.9335938V20.4985352c0-6.2202148-2.5644531-9.5161133-8.0566406-9.5161133 c-4.3945313,0-8.4199219,3.2958984-10.25,5.8579102c-0.734375-2.925293-3.296875-5.8579102-7.6933594-5.8579102 c-4.3945313,0-8.7851563,3.6650391-9.8886719,5.4946289v-4.3930664h-3.2890625v30.3891602h3.2890625V19.4047852 c1.8359375-2.5644531,5.4941406-5.1264648,8.7910156-5.1264648c4.0292969,0,5.8574219,2.5620117,5.8574219,6.9604492v21.234375 h3.2949219V19.4047852c1.8300781-2.9277344,5.4941406-5.1264648,8.7910156-5.1264648 c4.3925781,0,6.2207031,2.5620117,6.2207031,6.9604492V42.4731445"/>
				</g>
			</svg>
		</div>
	</div>
	);
}
export default Logo;