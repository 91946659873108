import React from 'react';
//import Logo from '../../components/Brand/Logo';
//import { brandConfig } from '../../api/brandConfig';

import './Footer.scss';

const Footer = () => (
	<div className="footer">
		<div className="footer--centre">

			<div className="footer__column">


				<div className="brand__logo--box">
					<svg id="InMemoriam" viewBox="0 0 292.013 40.119" className="brand__logo" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px">
						<g>
							<path className="logoScheme__img logoScheme__img--elephant" d="M7.484375,26.5478516c-2.0268555,0.2412109-3.1870117,0.2412109-4.1083984,0.0927734 c3.2006836-0.9951172,4.96875-1.9111328,6.2802734-2.5195313c3.0429688-1.4082031,6.762207-3.578125,9.6323242-6.2373047 c2.8891602-2.6630859,4.8720703-5.7358398,4.8544922-8.7041016C24.1391602,6.355957,22.4375,3.2583008,17.3334961,0.0908203 C13.3637695-0.2124023,9.6660156,0.2241211,6.8686523,1.7055664C-2.7705078,6.809082-1.4516602,26.203125,5.8012695,39.1152344 H9.484375c-1.284668-3.6689453-2.3535156-7.2626953-2.3657227-10.1308594 C7.1196289,28.1123047,7.222168,27.2939453,7.484375,26.5478516z M9.0805664,15.409668 c0.5009766,0,0.9082031,0.4057617,0.9082031,0.9077148c0,0.5014648-0.4072266,0.9072266-0.9082031,0.9072266 c-0.5014648,0-0.9086914-0.4057617-0.9086914-0.9072266C8.171875,15.8154297,8.5791016,15.409668,9.0805664,15.409668z  M43.0405273,12.6176758C40.7202148,7.5605469,30.4008789,2.1474609,20.6582031,0.5019531 c3.8017578,2.777832,5.4628906,5.5249023,5.4594727,8.6777344c-0.0170898,3.8583984-2.4174805,7.3027344-5.4882813,10.1533203 c-3.0883789,2.8535156-6.9267578,5.1455078-10.1699219,6.5927734c-0.4853516,0.2226563-0.7734375,0.5224609-1.0087891,1.0107422 c-0.2290039,0.4892578-0.359375,1.1855469-0.3574219,2.0478516c-0.0131836,2.5263672,1.1132813,6.2929688,2.480957,10.1308594 h7.2441406c-0.1708984-1.4951172-0.6196289-5.4648438-0.5927734-5.7851563 c0.0327148-0.3945313,0.2631836-0.7578125,0.8881836-0.8886719c0.7788086-0.1640625,1.0458984,0.5244141,1.0532227,0.7900391 c0.0283203,1.0068359,0.3881836,4.4326172,0.6176758,5.8837891h8.0131836 c-0.0258789-1.6621094,0.074707-3.1367188,0.2539063-4.2392578c0.0639648-0.390625,0.3618164-0.6904297,0.9873047-0.625 c0.6259766,0.0654297,0.9379883,0.5097656,0.8886719,0.9873047c-0.0942383,0.9072266-0.1503906,2.2949219-0.1479492,3.8769531 h8.3173828C44.4658203,29.7255859,45.3115234,17.5678711,43.0405273,12.6176758z"></path>
							<g>
								<rect className="logoScheme__img logoScheme__img--name" x="62.387207" y="11.1728516" width="1.9023438" height="22.0869141"></rect>
								<path className="logoScheme__img logoScheme__img--name" d="M80.2836914,33.2597656h1.7133789V22.2167969c0-3.6191406-1.902832-5.3330078-4.9516602-5.3330078 c-2.28125,0-4.5678711,1.5244141-5.7089844,2.8574219v-2.2841797h-1.7163086v15.8027344h1.7163086V21.2636719 c0.9487305-1.3339844,3.0439453-2.6660156,5.1386719-2.6660156c2.2871094,0,3.8085938,0.9550781,3.8085938,3.9980469V33.2597656"></path>
								<polyline className="logoScheme__img logoScheme__img--name" points="114.1772461,33.2597656 116.0800781,33.2597656 116.0800781,11.1728516 113.2226563,11.1728516 105.9897461,29.4511719 98.5644531,11.1728516 95.7075195,11.1728516 95.7075195,33.2597656 97.6098633,33.2597656 97.6098633,13.6489258 105.6049805,33.2597656 106.3676758,33.2597656 114.1772461,13.6489258 114.1772461,33.2597656 	"></polyline>
								<path className="logoScheme__img logoScheme__img--name" d="M128.0722656,18.5976563c4.0009766,0,5.7148438,3.234375,5.7148438,5.9042969h-11.4228516 C122.5537109,21.8320313,124.4599609,18.5976563,128.0722656,18.5976563 M120.4580078,25.2607422 c0,4.9521484,3.4277344,8.3789063,7.9980469,8.3789063c2.6669922,0,4.5693359-0.9501953,6.09375-2.6650391l-0.7626953-0.9541016 c-1.3320313,1.3330078-3.2353516,2.0947266-5.1416016,2.0947266c-3.8085938,0-6.0917969-2.8535156-6.28125-6.28125h13.1367188 V25.453125c0-4.5683594-2.4755859-8.5693359-7.4287109-8.5693359 C123.6972656,16.8837891,120.4580078,20.6914063,120.4580078,25.2607422z"></path>
								<path className="logoScheme__img logoScheme__img--name" d="M158.9199219,33.2597656h1.7128906V21.8320313c0-3.234375-1.3330078-4.9482422-4.3789063-4.9482422 c-2.09375,0-4.1884766,1.7138672-5.140625,3.0458984c-0.3808594-1.5214844-1.7128906-3.0458984-4.1894531-3.0458984 s-4.5712891,1.90625-5.3310547,2.8574219v-2.2841797h-1.7138672v15.8027344h1.7138672V21.2636719 c0.9521484-1.3339844,2.8544922-2.6660156,4.7607422-2.6660156c2.2832031,0,3.2382813,1.3320313,3.2382813,3.6191406v11.0429688 h1.7109375V21.2636719c0.7626953-1.5224609,2.6650391-2.6660156,4.3818359-2.6660156 c2.2802734,0,3.2353516,1.3320313,3.2353516,3.6191406V33.2597656"></path>
								<path className="logoScheme__img logoScheme__img--name" d="M172.6308594,32.1152344c-3.8105469,0-5.7158203-3.2353516-5.7158203-6.8544922 c0-3.4287109,1.9052734-6.6630859,5.7158203-6.6630859c3.6152344,0,5.7099609,3.234375,5.7099609,6.6630859 C178.3408203,28.8798828,176.2460938,32.1152344,172.6308594,32.1152344 M172.6308594,33.6396484 c4.5654297,0,7.6132813-3.8085938,7.6132813-8.3789063c0-4.5693359-3.0478516-8.3769531-7.6132813-8.3769531 c-4.5722656,0-7.6191406,3.8076172-7.6191406,8.3769531C165.0117188,29.8310547,168.0585938,33.6396484,172.6308594,33.6396484z"></path>
								<path className="logoScheme__img logoScheme__img--name" d="M184.6230469,33.2597656h1.7128906V21.6435547 c0.5722656-1.3330078,2.8574219-2.6640625,4.3779297-2.6640625c0.3818359,0,0.5742188,0,0.7636719,0v-2.0957031 c-2.0947266,0-3.8105469,1.5244141-5.1416016,3.2382813v-2.6650391h-1.7128906V33.2597656"></path>
								<path className="logoScheme__img logoScheme__img--name" d="M194.9042969,33.2597656h1.7138672V17.4570313h-1.7138672V33.2597656z M195.6660156,14.6005859 c0.7626953,0,1.3320313-0.5703125,1.3320313-1.3325195c0-0.5703125-0.5693359-1.3334961-1.3320313-1.3334961 c-0.5693359,0-1.1435547,0.7631836-1.1435547,1.3334961C194.5224609,14.0302734,195.0966797,14.6005859,195.6660156,14.6005859z"></path>
								<path className="logoScheme__img logoScheme__img--name" d="M211.8505859,30.0205078c-1.1416016,1.5244141-2.8535156,2.2880859-4.7617188,2.2880859 c-2.4716797,0-4.1845703-1.7158203-4.1845703-3.9980469c0-2.2880859,1.7128906-3.8085938,4.1845703-3.8085938 c1.9082031,0,3.6201172,0.7587891,4.7617188,2.0898438V30.0205078 M211.8505859,33.2597656h1.7138672v-11.234375 c0-3.4277344-2.4765625-5.1416016-5.7109375-5.1416016c-2.6679688,0-4.3798828,0.9521484-6.0947266,2.8574219l0.9521484,1.1435547 c1.5244141-1.7167969,3.0458984-2.2871094,4.9521484-2.2871094c2.2841797,0,4.1875,1.1435547,4.1875,3.6191406v3.2363281 c-1.3349609-1.7128906-3.046875-2.2841797-5.3300781-2.2841797c-2.6660156,0-5.5224609,1.7128906-5.5224609,5.1416016 c0,3.4257813,2.8564453,5.3291016,5.5224609,5.3291016c2.2832031,0,3.9951172-0.7617188,5.3300781-2.2861328V33.2597656z"></path>
								<path className="logoScheme__img logoScheme__img--name" d="M237.7441406,33.2597656h1.5253906V21.8320313c0-3.234375-1.3339844-4.9482422-4.1894531-4.9482422 c-2.2851563,0-4.3789063,1.7138672-5.3300781,3.0458984c-0.3818359-1.5214844-1.7148438-3.0458984-4.0009766-3.0458984 c-2.2851563,0-4.5673828,1.90625-5.1416016,2.8574219v-2.2841797h-1.7109375v15.8027344h1.7109375V21.2636719 c0.9541016-1.3339844,2.8564453-2.6660156,4.5712891-2.6660156c2.0947266,0,3.0458984,1.3320313,3.0458984,3.6191406v11.0429688 H229.9375V21.2636719c0.9511719-1.5224609,2.8574219-2.6660156,4.5722656-2.6660156 c2.2832031,0,3.234375,1.3320313,3.234375,3.6191406V33.2597656"></path>
							</g>
						</g>
					</svg>
				</div>
				<p>The In Memoriam logo and LifeTimeLine are Registered Trademarks of In Memoriam Group Pty. Ltd. ACN: 613 859 484 <a href="mailto:corporate@inmemoriam.com">corporate@inmemoriam.com</a></p>
				<p>All content is protected under copyright &copy; 2016 In Memoriam Group Pty Ltd.</p>
				<p>No content appearing on this website may be used without express written permission.</p>
				<p><a className="footer-ask-link" href="mailto:ask@inmemoriam.com">ask@inmemoriam.com</a></p>
				{/* <p>You can also connect with us on social media.</p>
				<p className="footer__socialIcons">
					<a href="https://www.facebook.com/lifetimeline.com.au/" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook-official"></i></a>
					<a href="https://twitter.com/LifeTimeLineCOM" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter-square"></i></a>
					<a href="https://www.linkedin.com/company/in-memoriam-group/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin-square"></i></a>
				</p> */}


				{/* <div className="footer__column--subBlock">
					<div className="footer__column--leftBlock">
						<a href="https://goo.gl/maps/xiXRzytE7Sx" target="_blank" rel="noopener noreferrer"><i className="fa fa-map-marker"></i></a>
					</div>
					<div className="footer__column--rightBlock">
						<h3>Sydney - Head Office</h3>
						<p>Suite 206, Level 2, 74 Pitt Street</p>
						<p>Sydney NSW 2000</p>
						<p>Australia</p>
					</div>
				</div>
				<div className="footer__column--subBlock">
					<div className="footer__column--leftBlock">
						<a href="https://goo.gl/maps/9tWT3DkT5H72" target="_blank" rel="noopener noreferrer"><i className="fa fa-map-marker"></i></a>
					</div>
					<div className="footer__column--rightBlock">
						<h3>Melbourne</h3>
						<p>P.O. Box 2108</p>
						<p>Brighton VIC 3186</p>
						<p>Australia</p>
					</div>
				</div> */}
			</div>

			<div className="footer__column">
				<h3>Trust Matters</h3>
				<p>We are passionate about the service we provide and a very large part of that is
					giving our users the confidence of knowing we do not on-sell, or even share, their private data with anyone.</p>
				<p>We do not sell or host any advertising so our users know when someone is looking at their memorial, that is all they will see. No ads.</p>

				{/*
				<h3>Privacy Policy</h3>
				<p>We are very serious about keeping your private data safe. You can view our full Privacy Policy, Terms and Conditions and Copyright Notice <a href="/privacy-policy">here</a>.</p>
				*/}
			</div>

			<div className="footer__column">
				<div className="brand__logo--box">
					<svg id="LifeTimeLine" viewBox="0 0 183.817 36" className="brand__logo" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px">
						<g><path id="slide-bubble" className="logoScheme__ltl logoScheme__ltl--slideBubble" d="M26.965,0H0v26.965h13.869l0,0c3.488,3.941,8.008,6.98,13.096,8.718c-2.3-2.301-3.908-5.332-4.359-8.719 h4.359V0z M22.694,19.572c0,0.15-0.122,0.272-0.272,0.272H4.542c-0.15,0-0.272-0.123-0.272-0.272V7.393 c0-0.15,0.122-0.271,0.272-0.271h17.88c0.15,0,0.272,0.122,0.272,0.271V19.572z"></path>
						<path id="-L-ifeTimeLineR" className="logoScheme__ltl logoScheme__ltl--name" d="M43.005,35.684H31.324V7.342h6.226v24.706h5.455V35.684z"></path>
						<path id="L-i-feTimeLineR" className="logoScheme__ltl logoScheme__ltl--name" d="M49.853,11.472H44.22v-4.13h5.633V11.472z M49.853,35.684H44.22V12.738h5.633V35.684z"></path>
						<path id="Li-f-eTimeLineR" className="logoScheme__ltl logoScheme__ltl--name" d="M62.017,16.137h-3.221v19.546h-5.633V16.137h-1.897v-3.399h1.897c0-2.016,0.254-3.419,0.76-4.21 c0.508-0.791,2.021-1.186,4.536-1.186h3.558v3.083h-1.08c-0.956,0-1.552,0.181-1.788,0.544c-0.235,0.362-0.353,0.952-0.353,1.769 h3.221V16.137z"></path>
						<path id="Lif-e-TimeLineR" className="logoScheme__ltl logoScheme__ltl--name" d="M76.276,25.96h-7.551v5.356c0,0.778,0.323,1.166,0.969,1.166c0.633,0,0.949-0.388,0.949-1.166v-4.091h5.633v4.131 c0,3.097-2.206,4.645-6.621,4.645c-4.374,0-6.563-1.614-6.563-4.842V17.422c0-3.229,2.188-4.842,6.563-4.842 c4.415,0,6.621,1.548,6.621,4.644V25.96z M70.644,21.829v-4.566c0-0.75-0.316-1.126-0.949-1.126c-0.646,0-0.969,0.376-0.969,1.126 v4.566H70.644z"></path>
						<path id="Life-T-imeLineR" className="logoScheme__ltl logoScheme__ltl--name" d="M89.574,11.472h-3.479v24.211h-6.227V11.472h-3.479v-4.13h13.184V11.472z"></path>
						<path id="LifeT-i-meLineR" className="logoScheme__ltl logoScheme__ltl--name" d="M96.64,11.472h-5.633v-4.13h5.633V11.472z M96.64,35.684h-5.633V12.738h5.633V35.684z"></path>
						<path id="LifeTi-m-eLineR" className="logoScheme__ltl logoScheme__ltl--name" d="M119.042,35.684h-5.633V17.342c0-0.698-0.321-1.047-0.959-1.047c-0.652,0-0.979,0.336-0.979,1.008v18.38h-5.633v-18.38 c0-0.698-0.319-1.047-0.958-1.047c-0.652,0-0.978,0.336-0.978,1.007v18.42h-5.633V12.738h5.633v0.811 c0.775-0.646,1.941-0.969,3.496-0.969c1.305,0,2.489,0.52,3.556,1.562c0.989-1.042,2.34-1.562,4.055-1.562 c0.974,0,1.891,0.293,2.746,0.879c0.857,0.586,1.286,1.4,1.286,2.441V35.684z"></path>
						<path id="LifeTim-e-LineR" className="logoScheme__ltl logoScheme__ltl--name" d="M133.914,25.96h-7.551v5.356c0,0.778,0.323,1.166,0.969,1.166c0.633,0,0.949-0.388,0.949-1.166v-4.091h5.633v4.131 c0,3.097-2.207,4.645-6.622,4.645c-4.374,0-6.561-1.614-6.561-4.842V17.422c0-3.229,2.187-4.842,6.561-4.842 c4.415,0,6.622,1.548,6.622,4.644V25.96z M128.281,21.829v-4.566c0-0.75-0.316-1.126-0.949-1.126c-0.646,0-0.969,0.376-0.969,1.126 v4.566H128.281z"></path>
						<path id="LifeTime-L-ineR" className="logoScheme__ltl logoScheme__ltl--name" d="M147.383,35.684h-11.681V7.342h6.225v24.706h5.456V35.684z"></path>
						<path id="LifeTimeL-i-neR" className="logoScheme__ltl logoScheme__ltl--name" d="M154.231,11.472h-5.634v-4.13h5.634V11.472z M154.231,35.684h-5.634V12.738h5.634V35.684z"></path>
						<path id="LifeTimeLi-n-eR" className="logoScheme__ltl logoScheme__ltl--name" d="M169.004,35.684h-5.633v-18.42c0-0.697-0.326-1.047-0.979-1.047c-0.651,0-0.979,0.336-0.979,1.007v18.46h-5.633V12.738 h5.633v0.771c0.753-0.62,1.916-0.929,3.488-0.929c1.017,0,1.955,0.276,2.813,0.83c0.858,0.553,1.288,1.364,1.288,2.431V35.684z"></path>
						<path id="LifeTimeLin-e-R" className="logoScheme__ltl logoScheme__ltl--name" d="M183.817,25.96h-7.551v5.356c0,0.778,0.323,1.166,0.968,1.166c0.633,0,0.95-0.388,0.95-1.166v-4.091h5.633v4.131 c0,3.097-2.208,4.645-6.622,4.645c-4.375,0-6.562-1.614-6.562-4.842V17.422c0-3.229,2.187-4.842,6.562-4.842 c4.414,0,6.622,1.548,6.622,4.644V25.96z M178.185,21.829v-4.566c0-0.75-0.317-1.126-0.95-1.126c-0.645,0-0.968,0.376-0.968,1.126 v4.566H178.185z"></path>
						<path id="LifeTimeLine-R-" className="logoScheme__ltl logoScheme__ltl--name" d="M181.656,7.342c1.216,0,2.161,0.944,2.161,2.122c0,1.203-0.945,2.135-2.174,2.135c-1.217,0-2.188-0.932-2.188-2.135 c0-1.177,0.971-2.122,2.188-2.122H181.656z M181.63,7.769c-0.931,0-1.63,0.764-1.63,1.695c0,0.944,0.699,1.695,1.656,1.695 c0.932,0.013,1.617-0.75,1.617-1.695c0-0.931-0.686-1.695-1.63-1.695H181.63z M181.307,10.563h-0.491V8.455 c0.193-0.039,0.466-0.078,0.814-0.078c0.401,0,0.583,0.064,0.737,0.168c0.117,0.09,0.208,0.259,0.208,0.453 c0,0.246-0.182,0.427-0.44,0.505v0.026c0.207,0.064,0.323,0.233,0.388,0.518c0.065,0.323,0.104,0.453,0.156,0.517h-0.531 c-0.064-0.077-0.103-0.259-0.168-0.504c-0.039-0.233-0.168-0.336-0.44-0.336h-0.232V10.563z M181.319,9.373h0.233 c0.271,0,0.492-0.091,0.492-0.311c0-0.194-0.143-0.324-0.453-0.324c-0.13,0-0.221,0.013-0.272,0.026V9.373z"></path>
						</g>
					</svg>
				</div>
				<p className="margin-bottom-35">Our companion service, LifeTimeLine®, is an ad-free, community-centric and permanent online space. It provides a safe and respectful place to create lasting life stories. It's a way to share your story around the world and across generations.</p>
				<p><a className="button-link" href="https://lifetimeline.com" target="_lifetimeline">Visit LifeTimeLine</a></p>
			</div>

		</div>
	</div>
);

export default Footer;