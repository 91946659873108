import React from 'react';

import './NavBar.scss';

function NavBar({ menuContent }) {

	return (
		<div className="nav-wrap">
			<div className="nav-menu">
				{menuContent}
			</div>
		</div>
  );
}
export default NavBar;