import React from 'react';
//import { Link } from 'react-router-dom';

//import logo from '../../svg/img-brand.svg';

import './Memorial.scss';

function Memorial(props) {

	const id = props.match.params.id.toLowerCase();
	let memorial = { found: false };

	if( id === 'chester' || id === 'chester-nutting' || id === 'chester-alfred-nutting' || id === 'chesternutting' || id === 'chesteralfrednutting') {
		memorial = {	found: true,
						firstname: 'Chester',
						middlename: 'Alfred',
						surname: 'Nutting',
						DOB: '05/11/1934',
						DOD: '09/09/2019',
						imageMain: 'https://files.inmemoriam.com/eb4160cd-ad66-454f-998c-867667123d52.jpg',
						description: [

'It is with great sadness the Nutting family advise of the passing of Chester Alfred Nutting.',
'Chester passed away peacefully at the age of 84. Preceded in death by his wife, Pamela, he is survived by his six children, seven grand children and three great grand children.',

'Loving father of Greg, Megan, Derek, Paul, Kate and Rawdyn. Proud grandfather of Josh, Ellen, Jacob, Ben, Sorren, Imogen and Gus. Great-grandfather of Lilia, Finn and Kennedy.',

'Chester will be fondly remembered by family and friends as a loving father, distinguished professional and a proud member of the Class of \'55 RMC Duntroon.'

						],
						funeral:
						{	agent: 'Gardenia Funerals',
							website: 'https://www.gardeniafunerals.com.au/',
							organiser: 'Michael Cox',
							date: '18/09/2019',
							time: '2:30pm',
							description: 'We invite friends and family to join us in saying farewell to Chester. The service will be followed by refreshments and a chance to reminisce and share stories of Chester.',
							locationName: 'Bunurong Memorial Park',
							locationWebsite: 'https://smct.org.au/our-locations/about-bunurong-memorial-park',
							locationAddress: '790 Frankston-Dandenong Rd, Bangholme, VIC. 3175',
							locationPhone:	'(03) 9788 9488',
							locationDetail: `The service will be conducted in The Sanctuary Chapel on the lake at the far end of the Memorial Park. You can utilise the carpark near the lake or park on the road next to the chapel. `
						},
						lifetimeline: 'https://lifetimeline.com/1'
					};
	}

	if( !memorial.found ) {
		return (
			<div className="main">
				<div className="section">
					<p className="island">We could not locate a memorial for {id}.</p>
				</div>
			</div>
		);
	}

	const renderLifetimelineLink = () => {
		return (
					<a className="button-link" href={memorial.lifetimeline} target="_lifetimeline">View {memorial.firstname}'s LifeTimeLine</a>

		);
	}



	return (
		<div className="main">
			<div className="section">
				<div className="memorial-box">
					<div className="memorial-left">
						<div className="memorial-image-main-box">
							<img className="memorial-image-main" src={memorial.imageMain} alt={`${memorial.firstname} ${memorial.surname}`} />
						</div>
						{renderLifetimelineLink()}
						<a className="button-link" href="mailto:family-of-chester-nutting@inmemoriam.com?body=Your message will be moderated and posted online.">Post a Message</a>
					</div>
					<div className="memorial-right">
						<div className="memorial-name">
							<h1>{memorial.firstname} {memorial.surname}</h1>
						</div>
						<div className="memorial-dates">
							<h3>{memorial.DOB} - {memorial.DOD}</h3>
						</div>
						<div className="memorial-description">
							{memorial.description.map((para,  index) => (<p key={index}>{para}</p>))}
						</div>
						<div className="funeral-details">
							<h2>Funeral Service Details</h2>
							<p>The funeral for {memorial.firstname} is on {memorial.funeral.date} at {memorial.funeral.time}.</p>
							<p>The venue is <a className="blue" href={memorial.funeral.locationWebsite} target="_exIM">{memorial.funeral.locationName}</a> at;</p>
							<p>{memorial.funeral.locationAddress}</p>
							<p className="subtle">{memorial.funeral.locationDetail}</p>
							<p>{memorial.funeral.description}</p>
							<p>The service is arranged by {memorial.funeral.organiser} from <a className="blue" href={memorial.funeral.website} target="_exIM">{memorial.funeral.agent}</a>.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default Memorial;