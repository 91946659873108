import React from 'react';
//import { Link } from 'react-router-dom';

import logo from '../../svg/img-brand.svg';

//import './ContactUs.css';

function ContactUs() {
	return (
		<div className="main">
			<div className="section">
				<img src={logo} className="App-logo" alt="logo" style={{ maxHeight: '100px'}} />
				<p className="island">Although we are flat out working on our product releases, we'de still love to hear from you.</p>
				<p className="island"><a className="button-link" href="mailto:ask@inmemroiam.com?subject=In Memoriam Enquiry">Email&nbsp;Us</a></p>
			</div>
		</div>
	);
}

export default ContactUs;